/* eslint-disable react/prop-types */
import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SocialLinks from "../components/socialLink"
import AdsCard from "../components/adsense"
//import AdSense from "react-adsense"

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const tags = post.frontmatter.tags
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext
    const { location } = this.props

    const dispAd = post.frontmatter.ad ? true : false

    const url = location.href
    const path = location ? location.pathname : "error: no path"

    // ここでタイトルとURLを取得
    /**
     * 
     * 一時停止 SocialLinksコンポーネントの下に配置
        <AdSense.Google
          client="ca-pub-2707967603950123"
          slot="6896669625"
          fomat="auto"
          style={{ display: "block" }}
        />
     */

    /* アドセンスを一時停止
     <div dangerouslySetInnerHTML={{ __html: post.html }} />
        {dispAd ? <AdsCard path={path} /> : <></>}
        <SocialLinks title={post.frontmatter.title} url={url} />
    */

    return (
      <Layout location={location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <h1>{post.frontmatter.title}</h1>
        <div className="post__info">
          <small>{post.frontmatter.date}</small>
          <div className="tags">
            {tags &&
              tags.map(tag => {
                {
                  return (
                    <Link key={tag} className="tag" to={`/tags/${tag}`}>
                      {tag}
                    </Link>
                  )
                }
              })}
          </div>
        </div>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
        <SocialLinks title={post.frontmatter.title} url={url} />

        <hr />

        <ul className="paginator">
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
        ad
      }
    }
  }
`
