import React from "react"
import PropTypes from "prop-types"
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share"

const SocialLinks = ({ title, url }) => {
  return (
    <div className="socialButton">
      <TwitterShareButton url={url} title={title}>
        <TwitterIcon round={true} size={42} />
      </TwitterShareButton>
      <FacebookShareButton url={url} quote={title}>
        <FacebookIcon round={true} size={42} />
      </FacebookShareButton>
    </div>
  )
}

SocialLinks.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

export default SocialLinks
